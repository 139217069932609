export default function initEventFiltering() {
  const categoryButtons = document.querySelectorAll('.category-button');
  const eventListingBlocks = document.querySelectorAll('.event-listing-block');

  categoryButtons.forEach(button => {
    button.addEventListener('click', function() {
      handleCategoryButtonClick(button, eventListingBlocks);
    });
  });

  handleUrlParams();

  eventListingBlocks.forEach(eventListingBlock => {
    updateNumberOfLinkBlocksClass(eventListingBlock);
  });
}

function handleUrlParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const initialCategory = urlParams.get('category');

  if(initialCategory) {
    const categoryButton = document.querySelector(`.category-button[data-category="${initialCategory}"]`);
    if(categoryButton) {
      categoryButton.click();
    }
  }
}

/**
 * @param { HTMLElement } button The clicked category button
 * @param { NodeList } eventListingBlocks The collection of event listing blocks.
 */
function handleCategoryButtonClick(button, eventListingBlocks) {
  document.querySelectorAll('.category-button').forEach(btn => {
    btn.classList.remove('selected');
  });
  button.classList.add('selected');

  const selectedCategory = button.getAttribute('data-category');

  eventListingBlocks.forEach(eventListingBlock => {
    toggleVisibleEventsForSelectedCategory(eventListingBlock, selectedCategory);
  });

  updateUrlParams(selectedCategory);
}

/**
 * @param { HTMLElement } eventListingBlock
 * @param { string }selectedCategory
 */
function toggleVisibleEventsForSelectedCategory(eventListingBlock, selectedCategory) {
  const hasMatchingEvent = matchesSelectedCategory(eventListingBlock, selectedCategory);
  eventListingBlock.style.display = hasMatchingEvent ? 'flex' : 'none';

  const eventLinkBlocks = eventListingBlock.querySelectorAll('.event-link-block');
  eventLinkBlocks.forEach(block => {
    const hasMatchingEvent = matchesSelectedCategory(block, selectedCategory);
    block.style.display = hasMatchingEvent ? 'flex' : 'none';
  });

  updateNumberOfLinkBlocksClass(eventListingBlock);
}

/**
 * @param { HTMLElement } eventListingBlock Event listing block to update.
 */
function updateNumberOfLinkBlocksClass(eventListingBlock) {
  const linkBlocks = eventListingBlock.querySelectorAll('.event-link-block');
  const visibleLinkBlocks = Array.from(linkBlocks).filter(linkBlock => linkBlock.style.display !== 'none');

  eventListingBlock.classList.remove('single', 'double');
  switch (visibleLinkBlocks.length) {
    case 1:
      eventListingBlock.classList.add('single');
      break;
    case 2:
      eventListingBlock.classList.add('double');
      break;
  }
}

/**
 * @param { HTMLElement } block Block with categories
 * @param { string } selectedCategory The selected category
 * @returns { boolean } True if there is a match, false otherwise.
 */
function matchesSelectedCategory(block, selectedCategory) {
  const blockCategories = getBlockCategories(block);
  return blockCategories.some(category => selectedCategory === 'All' || selectedCategory === category);
}

/**
 * Get the categories from listing block or category from item.
 * @param {HTMLElement} block Event listing block or event link block.
 * @returns {Array} An array of categories.
 */
function getBlockCategories(block) {
  const blockCategories = block.getAttribute('data-categories') ?? block.getAttribute('data-category');
  return blockCategories ? blockCategories.split(',') : [];
}

/**
 * @param {string} selectedCategory The selected category.
 */
function updateUrlParams(selectedCategory) {
  const url = new URL(window.location.href);

  if(selectedCategory.toLowerCase() === 'all') {
    url.searchParams.delete('category');
  } else {
    url.searchParams.set('category', selectedCategory);
  }

  history.replaceState({}, document.title, url.toString());
}
