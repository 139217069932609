export default function initTableWrapper() {

  const tables = document.querySelectorAll('table');

  tables.forEach((table) => {
    wrapElement(table, 'table-wrapper');
  });
}

/**
 * @param {HTMLElement} element - The HTML element to be wrapped.
 * @param {string} wrapperClass - The class to be added to the wrapper div.
 * @returns {void}
 */
function wrapElement(element, wrapperClass) {
  const wrapper = document.createElement('div');
  wrapper.classList.add(wrapperClass);
  element.parentNode?.insertBefore(wrapper, element);

  wrapper.appendChild(element);
}

